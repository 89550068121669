import gql from "graphql-tag";

export const AppConfigFragment = gql`
  fragment AppConfigFragment on Organization {
    logo
    beta
    isMultilingual
    displayName
    quickExitUrl
    showSpeakfullyTermsLink
    showResourcesTab
    showSupportTab
    submissionFormTitle
    employeeTerms {
      url
      text
    }
    resources {
      id
      title
      description
      resourceUrl
      lastModified
      images {
        id
        filepath
        incidentId
        filename
        fileUrl
        isTitle
      }
    }
  }
`;

export const SubmissionConfigFragment = gql`
    fragment SubmissionConfigFragment on Organization {
      requireExperienceExplanation
      experienceDateRequired
      allowAnonymous
      messagingEnabled
      identityDisclosureVerbiage
      allowIdentityDisclosure
      allowAnonymousSubmission
      allowIdentityDisclosureAndUpdate
      experienceDescriptionInstruction
      allowExternalShare
      dataDisposalPolicy {
        autoDeleteSubmissionData
        daysAfterCompletion
      }
      noteActionOptions {
        actionType
        actionDate
        media
        otherAction
      }
      resolutionOptions {
        status
        media
        statusText
        statusDetails
      }
      personsInvolvedProperties {
        types
        titles
      }
    }
  `

export const AnalyticsConfigFragment = gql`
  fragment AnalyticsConfigFragment on Organization {
    peopleAnalyticsEnabled
    dataFilterFactors {
      id
      displayName
      factor
      group
      order
    }
  }
`;

export const OrgFragment = gql`
					fragment OrgFragment on Organization {
						id
            isIntegratedOrg
            isHotline
						name
						planName
						trialStartDate
            webForm {
              isWebform
              title
              type
              coreQueueName
            }
            homepageConfig {
              welcomeText
              introductionText
              issueReportButtonText
              trackSubmissionButtonText
           
            }
            trackYourSubmissionConfig {
              trackSubmissionTitleText
              trackSubissionSubtitleText
              trackSubissionDetailText
            }
            orderConfig {
              dateOfExperience
              attachment
              experienceDetails
              personsInvolved
              identity
            }
						appConfig {
              emailCustomizations {
                messageTemplateId
                pinSuccessMessageTemplateId
              }
              askDateOfExperience
              askAddInvolvedPeople
              closedCaseNotification
              isPasswordRequired
							logo
							beta
							isMultilingual
							displayName
							quickExitUrl
							showSpeakfullyTermsLink
							allowAddingPersonalEmail
							showResourcesTab
              showSupportTab
              submissionFormTitle
							employeeTerms {
								url
								text
							}
							resources {
								id
								title
								description
								resourceUrl
								lastModified
								images {
									id
									filepath
									incidentId
									filename
									fileUrl
									isTitle
								}
							}
						}
						submissionConfig {
              requireExperienceExplanation
              keyTitle
              keySubtitle
              keyNextStepsTitle
              keyNextStepsText
              askDateOfExperience
              askAddInvolvedPeople
              askAddAttachments
							experienceDateRequired
							dataDisposalPolicy {
								autoDeleteSubmissionData
								daysAfterCompletion
							}
							noteActionOptions {
								actionType
								actionDate
								media
								otherAction
							}
							resolutionOptions {
								status
								media
								statusText
								statusDetails
							}
							personsInvolvedProperties {
								types
								titles
							}
							allowAnonymous
							messagingEnabled
              identityDisclosureVerbiage
							allowIdentityDisclosure
              allowAnonymousSubmission
              allowIdentityDisclosureAndUpdate
							experienceDescriptionInstruction
							allowExternalShare
						}
						analyticsConfig {
							peopleAnalyticsEnabled
							dataFilterFactors {
								id
								displayName
								factor
								group
								order
							}
						}
						surveys {
							id
							title
							startDate
							endDate
							userIds
							createdBy
							createdDate
							questions {
                multipleChoice
                openTextField
								priority
								groupLabel
                previousLabel
								questionType
								label
								multi
								instruction
							  questionText
								answerOptions {
                  overrideIdentityDisclosure
									answerText
									media
                  displayTextFieldLabel
                  displayTextField
									questions {
										groupLabel
										questionType
										label
										multi
										instruction
										questionText
										answerOptions {
                      overrideIdentityDisclosure
											answerText
											media
                      displayTextFieldLabel
                      displayTextField
										}
										skippable
									}
								}
								skippable
							}
						}
						currentSubmissions {
							folderType
							questions {
                multipleChoice
                openTextField
								priority
								submissionType
								determinesSubmissionType
								groupLabel
                previousLabel
								questionType
								questionMedia
								label
								multi
								instruction
								questionText
								skippable
								answerOptions {
                  overrideIdentityDisclosure
									answerText
									media
                  displayTextFieldLabel
                  displayTextField
									tag {
										category
										tag
										id
										isActive
									}
									questions {
										groupLabel
										questionType
										label
										multi
										instruction
										questionText
										skippable
										answerOptions {
                    overrideIdentityDisclosure
                    displayTextFieldLabel
                    displayTextField
											answerText
											media
											tag {
												category
												tag
												id
												isActive
											}
										}
									}
								}
							}
						}
					}
				`

export const FolderNoteFragment = gql`
  fragment FolderNoteFragment on SharedFolder {
    id
    notes {
      id
      attachments {
        id
        filepath
        noteId
        filename
        fileUrl
        mimetype
      }
      comment
      action {
        actionType
        otherAction
        actionDate
        media
      }
      userId
      date
    }
  }
`;

export const MessageFragment = gql `
  fragment MessageFragment on Message {
    id
    userId
    message
    isCoreUser
    createdDate
    modifiedDate
    attachments {
      id
      filepath
      messageId
      filename
      fileUrl
    }
  }
`;

export const SharedFolderFragment = gql`
					fragment SharedFolderFragment on SharedFolder {
						expiryDate
						id
            org {
              isIntegratedOrg
            }
						name
            reference_id
            isIntegratedFolder
						folderType
						willingToTalk
						tags {
							tag
							_id
							category
							isActive
						}
						currentStatusDate
						sharedWith {
							id
							userId
							sharedBy
							shareMethod
							expiryDate
							notificationsEnabled
							email
              isActive
              unsharedBy
						}
						user {
							id
							email
						}
						org {
							name
							id
              isIntegratedOrg
							appConfig {
								displayName
							}
              webForm {
                isWebform
                title
                type
                coreQueueName
              }
              homepageConfig {
                welcomeText
                introductionText
                issueReportButtonText
                trackSubmissionButtonText
              }
              trackYourSubmissionConfig {
                trackSubmissionTitleText
                trackSubissionSubtitleText
                trackSubissionDetailText
              }
              orderConfig {
                dateOfExperience
                attachment
                experienceDetails
                personsInvolved
                identity
              }
							submissionConfig {
                requireExperienceExplanation
                experienceDescriptionInstruction
                keyTitle
                keySubtitle
                keyNextStepsTitle
                keyNextStepsText
                askDateOfExperience
                askAddInvolvedPeople
                askAddAttachments
								externalShareDays
								allowExternalShare
								noteActionOptions {
									actionType
									actionDate
									media
									otherAction
								}
								resolutionOptions {
									status
									media
									statusText
									statusDetails
								}
								personsInvolvedProperties {
									types
									titles
								}
							}
						}
						personsInvolved {
							name
							type
							title
							userId
						}
						statusHistory {
							status
							date
							userId
						}
						currentStatus
						actionHistory {
							action
							date
							userId
						}
						isActive
						isPrivate
						workflow {
							statuses {
								name
								triggeredBy
                allowedCoreStatus
							}
						}
						incidents {
							id
							description
							isDraft
							dateOfIncident
							statusHistory {
								status
								date
								userId
							}
							attachments {
								id
								filepath
								incidentId
								filename
								fileUrl
							}
							answers {
                textFieldValue
								questionLabel
								questionType
								questionGroupLabel
								questionMedia
								option
								otherResponse
                tag
							}
						}
						resolution {
              actions {
                action
                media
              }
              details
              otherAction
						}
						messages {
							userId
							message
							createdDate
							modifiedDate
						}
						isAnonymous
						submittedDate
					}
				`

export const OrgFolderFragment = gql`
fragment OrgFolderFragment on OrgFolder {
  id
  isActive
  currentStatus
  currentStatusDate
  submittedDate
  name
  isIntegratedFolder
  incidents {
    id
    description
    isDraft
    dateOfIncident
    witnesses {
      name
      relationType
    }
    statusHistory {
      status
      date
      userId
    }
    attachments {
      id
      filepath
      incidentId
      filename
      fileUrl
      mimetype
    }
    answers {
      textFieldValue
      questionLabel
      questionType
      questionGroupLabel
      questionMedia
      option
      otherResponse
      tag
    }
  }
}
				`
        export const FolderFragment = gql`
        fragment FolderFragment on Folder {
          id
          name
          willingToTalk
          reference_id
          isIntegratedFolder
          currentStatusDate
          tags {
            tag
            _id
            category
            isActive
          }
          sharedWith {
            id
            userId
            sharedBy
            shareMethod
            expiryDate
            notificationsEnabled
            email
            isActive
            unsharedBy
          }
          folderType
          user {
            id
          }
          org {
            id
            isIntegratedOrg
            name
            appConfig {
              displayName
            }
            webForm {
              isWebform
              title
              type
              coreQueueName
            }
            homepageConfig {
              welcomeText
              introductionText
              issueReportButtonText
              trackSubmissionButtonText

            }
            trackYourSubmissionConfig {
              trackSubmissionTitleText
              trackSubissionSubtitleText
              trackSubissionDetailText
            }
            orderConfig {
              dateOfExperience
              attachment
              experienceDetails
              personsInvolved
              identity
            }
            submissionConfig {
              requireExperienceExplanation
              experienceDescriptionInstruction
              keyTitle
              keySubtitle
              keyNextStepsTitle
              keyNextStepsText
              askDateOfExperience
              askAddInvolvedPeople
              askAddAttachments
              externalShareDays
              resolutionOptions {
                status
                media
                statusText
                statusDetails
              }
              personsInvolvedProperties {
                types
                titles
              }
            }
          }
          personsInvolved {
            name
            type
            title
            userId
          }
          statusHistory {
            status
            date
            userId
          }
          currentStatus
          actionHistory {
            action
            date
            userId
          }
          isActive
          isPrivate
          workflow {
            statuses {
              name
              triggeredBy
              allowedCoreStatus
            }
          }
          incidents {
            id
            description
            isDraft
            dateOfIncident
            witnesses {
              name
              relationType
            }
            statusHistory {
              status
              date
              userId
            }
            attachments {
              id
              filepath
              incidentId
              filename
              fileUrl
              mimetype
            }
            answers {
              textFieldValue
              questionLabel
              questionType
              questionGroupLabel
              questionMedia
              option
              otherResponse
              tag
            }
          }
          messages {
            userId
            message
            createdDate
            modifiedDate
          }
          isAnonymous
          submittedDate
        }
      `


export const SubmissionFragment = gql`
					fragment SubmissionFragment on AnonymousSubmission {
						id
						name
						reportedBy
						contactInfo {
							email
							countryCode
							phoneNumber
						}
						currentStatus
						currentStatusDate
						folderType
						submittedDate
						isAnonymous
						tags {
							tag
							_id
							category
							isActive
						}
						org {
							name
							id
              isIntegratedOrg
							appConfig {
								displayName
							}
              webForm {
                isWebform
                title
                type
                coreQueueName
              }
              homepageConfig {
                welcomeText
                introductionText
                issueReportButtonText
                trackSubmissionButtonText

              }
              trackYourSubmissionConfig {
                trackSubmissionTitleText
                trackSubissionSubtitleText
                trackSubissionDetailText
              }
              orderConfig {
                dateOfExperience
                attachment
                experienceDetails
                personsInvolved
                identity
              }
							submissionConfig {
                requireExperienceExplanation
                experienceDescriptionInstruction
                keyTitle
                keySubtitle
                keyNextStepsTitle
                keyNextStepsText
                askDateOfExperience
                askAddInvolvedPeople
                askAddAttachments
								externalShareDays
								allowExternalShare
								noteActionOptions {
									actionType
									actionDate
									media
									otherAction
								}
								resolutionOptions {
									status
									media
									statusText
									statusDetails
								}
								personsInvolvedProperties {
									types
									titles
								}
							}
						}
						personsInvolved {
							name
							type
							title
						}
						statusHistory {
							status
							date
							userId
						}
						updates {
							id
							userId
							text
							date
						}
						messages {
							id
							userId
							message
							createdDate
							modifiedDate
							attachments {
								id
								filepath
								messageId
								filename
								fileUrl
							}
						}
						currentStatus
						actionHistory {
							action
							date
							userId
						}
						isActive
						isPrivate
						workflow {
							statuses {
								name
								triggeredBy
                allowedCoreStatus
							}
						}
						incidents {
							id
							description
							isDraft
							dateOfIncident
							statusHistory {
								status
								date
								userId
							}
							attachments {
								id
								filepath
								incidentId
								filename
								fileUrl
								mimetype
							}
							answers {
                textFieldValue
								questionMedia
								questionLabel
								questionType
								questionGroupLabel
								option
								otherResponse
                tag
							}
						}
						resolution {
							actions {
								action
								media
							}
							details
              otherAction
						}
						sharedWith {
							id
							userId
							sharedBy
							shareMethod
							expiryDate
							notificationsEnabled
							email
              isActive
              unsharedBy
						}
					}
				`

        export const OrgWithFoldersFragment = gql`
        fragment OrgWithFoldersFragment on OrganizationWithFolders {
          id
          isIntegratedOrg
          name
          planName
          trialStartDate
          webForm {
            isWebform
            title
            type
            coreQueueName
          }
          homepageConfig {
            welcomeText
            introductionText
            issueReportButtonText
            trackSubmissionButtonText
          }
            trackYourSubmissionConfig {
              trackSubmissionTitleText
              trackSubissionSubtitleText
              trackSubissionDetailText
            }
          orderConfig {
            dateOfExperience
            attachment
            experienceDetails
            personsInvolved
            identity
          }
          appConfig {
            askDateOfExperience
            askAddInvolvedPeople
            closedCaseNotification
            isPasswordRequired
            logo
            beta
            isMultilingual
            displayName
            quickExitUrl
            showSpeakfullyTermsLink
            allowAddingPersonalEmail
            showResourcesTab
            showSupportTab
            submissionFormTitle
            employeeTerms {
              url
              text
            }
            resources {
              id
              title
              description
              resourceUrl
              lastModified
              images {
                id
                filepath
                incidentId
                filename
                fileUrl
                isTitle
              }
            }
          }
          submissionConfig {
            requireExperienceExplanation
            keyTitle
            keySubtitle
            keyNextStepsTitle
            keyNextStepsText
            askDateOfExperience
            askAddInvolvedPeople
            askAddAttachments
            experienceDateRequired
            dataDisposalPolicy {
              autoDeleteSubmissionData
              daysAfterCompletion
            }
            noteActionOptions {
              actionType
              actionDate
              media
              otherAction
            }
            resolutionOptions {
              status
              media
              statusText
              statusDetails
            }
            personsInvolvedProperties {
              types
              titles
            }
            allowAnonymous
            messagingEnabled
            identityDisclosureVerbiage
            allowIdentityDisclosure
            allowAnonymousSubmission
            allowIdentityDisclosureAndUpdate
            experienceDescriptionInstruction
            allowExternalShare
          }
          analyticsConfig {
            peopleAnalyticsEnabled
            dataFilterFactors {
              id
              displayName
              factor
              group
              order
            }
          }
          surveys {
            id
            title
            startDate
            endDate
            userIds
            createdBy
            createdDate
            questions {
              multipleChoice
              openTextField
              priority
              groupLabel
              previousLabel
              questionType
              label
              multi
              instruction
              questionText
              answerOptions {
                overrideIdentityDisclosure
                answerText
                media
                displayTextFieldLabel
                displayTextField
                questions {
                  groupLabel
                  questionType
                  label
                  multi
                  instruction
                  questionText
                  answerOptions {
                    overrideIdentityDisclosure
                    answerText
                    media
                    displayTextFieldLabel
                    displayTextField
                  }
                  skippable
                }
              }
              skippable
            }
          }
          currentSubmissions {
            folderType
            questions {
              multipleChoice
              openTextField
              priority
              submissionType
              determinesSubmissionType
              groupLabel
              questionType
              questionMedia
              label
              multi
              instruction
              questionText
              skippable
              answerOptions {
                overrideIdentityDisclosure
                answerText
                media
                displayTextFieldLabel
                displayTextField
                tag {
                  category
                  tag
                  id
                  isActive
                }
                questions {
                  groupLabel
                  questionType
                  label
                  multi
                  instruction
                  questionText
                  skippable
                  answerOptions {
                  overrideIdentityDisclosure
                  displayTextFieldLabel
                  displayTextField
                    answerText
                    media
                    tag {
                      category
                      tag
                      id
                      isActive
                    }
                  }
                }
              }
            }
          }
          folders {
            ...OrgFolderFragment
          }
          
        }
        ${OrgFolderFragment}
      `


      